import { displayGratuityFeature } from 'businessLogic/Payment/Gratuity';
import HttpClient from 'server/helpers/HttpClient';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { shouldShowCKMonthlyTest } from 'store/ixp/selector';
import { saleSelectors } from 'store/sale/selectors';

function getFeatureEligibility(store = {}) {
  const { sale, wallet, ixp, insight = {} } = store;
  const {
    isPayable,
    shouldShowSchedulePay,
    shouldShowAutopay,
    payorSegmentation: { hasMintAccount, hasMailchimpAccount, hasQuickbooksAccount } = {},
  } = insight;
  const feature_eligibility = [];
  feature_eligibility.push(getMerchantLogoStatus(store));

  if (isPayable) {
    const isTipsEnabled = displayGratuityFeature(store);
    if (isTipsEnabled) {
      feature_eligibility.push('tips');
    }
  }
  if (shouldShowSchedulePay) {
    feature_eligibility.push('schedule_pay');
  }
  if (shouldShowAutopay) {
    feature_eligibility.push('auto_pay');
  }
  if (hasMintAccount || hasMailchimpAccount || hasQuickbooksAccount) {
    const account = hasQuickbooksAccount
      ? 'qbo'
      : hasMailchimpAccount
      ? 'mailchimp'
      : 'creditkarma';
    feature_eligibility.push(`${account}_login`);
  }
  const isCKMonthlyTestEnabled = shouldShowCKMonthlyTest(
    { ixp, featureFlags: {} },
    wallet?.enabledPaymentMethods?.length
  );
  if (isCKMonthlyTestEnabled) {
    feature_eligibility.push('creditkarma_monthly');
  }
  if (saleSelectors.achOnlineConvenienceFeeEnabledSelector(sale)) {
    const feeAmount =
      saleSelectors.achOnlineConvenienceFeeAmountPaidSelector(sale) ||
      saleSelectors.achOnlineConvenienceFeeAmountSelector(sale);
    feature_eligibility.push(`convenience_fee_amount_${feeAmount}`);
  }
  return feature_eligibility;
}
function getMerchantLogoStatus(store) {
  const { companyInfo = {} } = store;
  let merchantLogoStatus = '',
    companyLogoUrl = '';
  companyLogoUrl = companyInfoSelectors.logoSelector(companyInfo);

  merchantLogoStatus = companyLogoUrl ? 'MerchantHasLogo' : 'MerchantHasNoLogo';
  return merchantLogoStatus;
}
function getScreenPrevious() {
  const isFromSignIn = (localStorage?.getItem('from_sign_in') ?? 'false') === 'true';
  const isFromSignUp = (localStorage?.getItem('from_sign_up') ?? 'false') === 'true';

  let result = '';
  if (isFromSignIn) {
    result = 'sign_in_page';
    localStorage.removeItem('from_sign_in');
  } else if (isFromSignUp) {
    result = 'sign_up_page';
    localStorage.removeItem('from_sign_up');
  }
  return result;
}
function getLinkSource() {
  try {
    const queryString = new URLSearchParams(window?.location?.search);
    const utm = queryString.get('utm_source');
    const cta = queryString.get('cta');
    if (cta === 'viewinvoicenow') {
      return 'email';
    } else if (cta === 'pdfbutton') {
      return 'pdfbutton';
    } else if (cta === 'paylinkbuybutton') {
      return 'paylinkbuybutton';
    } else if (utm === 'sms') {
      return 'sms';
    } else if (utm === 'sharelink') {
      return 'sharelink';
    }
    return null;
  } catch (e) {
    return null;
  }
}
async function isAdBlockEnabled() {
  const handleAdBlockCheckHttpError = () => {
    isAdBlockEnabled = true;
  };
  let isAdBlockEnabled = false;
  const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

  try {
    await HttpClient.getInstance()
      .get(googleAdUrl)
      .catch((e) => {
        handleAdBlockCheckHttpError(e);
      });
  } catch (e) {
    handleAdBlockCheckHttpError(e);
  }
  return isAdBlockEnabled;
}

export {
  getFeatureEligibility,
  getScreenPrevious,
  getMerchantLogoStatus,
  isAdBlockEnabled,
  getLinkSource,
};
