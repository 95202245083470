import HttpClient from 'server/helpers/HttpClient';
import SegmentIO from 'reporting/SegmentIO';

const httpClient = HttpClient.getInstance();
const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/Auth';
export default class Auth {
  constructor({ initialState, config }) {
    this.config = config;
    this._data = initialState.auth;
  }

  updateData(data) {
    this._data = { ...this._data, ...data };
  }

  signIn(entityId, endpoints) {
    localStorage.setItem('from_sign_in', 'true');
    SegmentIO.clickSignIn('link', 'header');
    SegmentIO.transactionEngaged({
      activity_type: 'sign_in',
      ui_object: 'link',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
      ui_object_detail: 'wallet_sign_in',
    });

    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'signIn',
      action: 'redirect',
    });
    window.location.href = endpoints.signInURL;
  }

  signUp(entityId, endpoints) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'signUp',
      action: 'redirect',
    });
    localStorage.setItem('from_sign_up', 'true');
    SegmentIO.transactionEngaged({
      activity_type: 'sign_up',
      ui_object: 'link',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
      ui_object_detail: 'wallet_sign_up',
    });

    window.location.href = endpoints.signUpURL;
  }

  async signOut(token) {
    const { ssrtid, portal } = this.config;
    const endpoint = `/${portal}/rest/authenticate/logout`;
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'signOut',
      action: 'clicked',
    });

    await httpClient({
      url: endpoint,
      method: 'DELETE',
      ssrtid,
      token,
      ignoreProfilingInterceptor: true,
      event: 'signOut',
    });

    setTimeout(() => {
      window.location.reload();
    }, 0);
  }

  get authLevel() {
    return this._data.authLevel;
  }

  get realmId() {
    return this._data.realmId;
  }

  get entityId() {
    return this._data.entityId;
  }

  get isUserSignedIn() {
    return this._data.isUserSignedIn;
  }

  get isEntityPromoted() {
    return this._data.isEntityPromoted;
  }

  get ticket() {
    return this._data.ticket;
  }

  get authToken() {
    return this._data.authToken;
  }

  get regionIsoCode() {
    return this._data.regionIsoCode;
  }

  get recipientEmail() {
    return this._data.recipientEmail;
  }

  get syncToken() {
    return this._data.syncToken;
  }
}
