import { PayloadAction } from '@reduxjs/toolkit';

import { sendEmailNotification, sendNpeFreeTextEmail } from 'businessLogic/Insight/Growth';
import { mark } from 'shared/clientUtils';
import { ViewType } from 'shared/types';
import { encrypt } from 'shared/utils';
import { saleSelectors } from 'store/sale/selectors';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { Insight } from 'types/Insight';

const initialState: Partial<Insight> = {};

const { reducer, actions } = sliceFactory({
  name: 'insight',
  initialState,
  reducers: {
    getRiskAssessment(state, action: PayloadAction<Pick<Insight, 'riskProfileToken'>>) {
      const { riskProfileToken } = action.payload;
      state.riskProfileToken = riskProfileToken;
    },

    updateIsAvailableBalance(state, action: PayloadAction<Insight['isAvailableBalance']>) {
      state.isAvailableBalance = action.payload;
    },
    updateInProgress(state) {
      state.updateInProgress = true;
    },
    updateFail(state) {
      state.updateInProgress = false;
    },
    updateSuccess(state) {
      state.updateInProgress = false;
    },
    updateShouldShowSchedulePay(state, action: PayloadAction<Insight['shouldShowSchedulePay']>) {
      state.shouldShowSchedulePay = action.payload;
    },
    updateShouldShowAutopay(state, action: PayloadAction<Insight['shouldShowAutopay']>) {
      state.shouldShowAutopay = action.payload;
    },
    updateCanCancelSchedule(state, action: PayloadAction<Insight['canCancelSchedule']>) {
      state.canCancelSchedule = action.payload;
    },
    syncBill(state, action: PayloadAction<Pick<Insight, 'billId' | 'billPaymentId'>>) {
      const { billId, billPaymentId } = action.payload;
      state.billId = billId;
      state.billPaymentId = billPaymentId;
      state.syncBillStatus = 'SUCCESS';
    },
    nonPayEnableEvent(state, action) {
      state.nonPayEnableEmailStatus = action.payload;
    },
    npeFreeTextEmailEvent(state, action) {
      state.freeTextEmailOINPEventStatus = action.payload;
    },
    updateIsBulkModalOpen(state, action) {
      if (state.bulkPayments) {
        state.bulkPayments.isModalOpen = action.payload;
      }
    },
    updateUnpaidInvoices(state, action) {
      if (state.bulkPayments) {
        state.bulkPayments.unpaidInvoices = action.payload;
      }
    },
    updateIsBulkCheckout(state, action) {
      if (state.bulkPayments) {
        state.bulkPayments.isBulkCheckout = action.payload;
      }
    },
  },
});

export const insightReducer = reducer;
export const insightActions = actions;

export const getRiskProfileToken = thunkActionFactory(
  async ({ dispatch, state, businessLogic }) => {
    const {
      featureFlags,
      config: { ssrtid },
      insight: { token },
    } = state;

    let getRiskProfileTokenMark = mark('dispatch(getRiskProfileToken())');
    let riskProfileToken = await businessLogic.insight.getRiskProfileToken({
      featureFlags,
      ssrtid,
      token,
    });

    if (riskProfileToken) {
      dispatch(insightActions.getRiskAssessment({ riskProfileToken }));
    }
    getRiskProfileTokenMark.finish();
  }
);

export const updateShouldShowSchedulePay = thunkActionFactory(({ dispatch, state }) => {
  const {
    featureFlags,
    sale,
    insight: { canBeFullyPaid },
  } = state;

  const { scheduleInfo } = sale;
  const dueDate = saleSelectors.dueDateSelector(sale);
  const isACHOnlineFeeEnabled = saleSelectors.achOnlineConvenienceFeeEnabledSelector(sale);
  const spEligible = scheduleInfo && scheduleInfo.schedulePayEligible;
  const isDateSPValid =
    (typeof dueDate === 'string' && new Date(dueDate.replace(/-/g, '/')) > new Date()) ||
    featureFlags['should-ignore-due-date-schedule-pay'];

  if (spEligible && isDateSPValid && canBeFullyPaid && !isACHOnlineFeeEnabled) {
    dispatch(insightActions.updateShouldShowSchedulePay(true));
  }
});

export const updateShouldShowAutopay = thunkActionFactory(({ dispatch, state }) => {
  const {
    sale: { scheduleInfo, saleTerm },
    insight: { canBeFullyPaid },
  } = state;

  const validAP =
    scheduleInfo &&
    scheduleInfo.autoPayEligible &&
    !scheduleInfo.scheduleTemplateId &&
    !(
      scheduleInfo.scheduleStatus === 'DECLINED' ||
      scheduleInfo.scheduleStatus === 'ERROR' ||
      scheduleInfo.scheduleStatus === 'PROCESSED'
    );

  const isValidSaleTerm = saleTerm && !!saleTerm.name;

  if (validAP && canBeFullyPaid && isValidSaleTerm) {
    dispatch(insightActions.updateShouldShowAutopay(true));
  }
});

export const updateCanCancelSchedule = thunkActionFactory(({ dispatch, state }) => {
  const { sale, auth } = state;

  let currentUserId = null;
  let schedulerId = null;

  try {
    const { scheduleInfo } = sale;
    currentUserId = auth.entityId;
    schedulerId = scheduleInfo && scheduleInfo.payor && scheduleInfo.payor.entity.globalId.localId;
  } catch {
    // eslint-disable-next-line no-empty
  }

  const isUserTheScheduler = !!currentUserId && !!schedulerId && currentUserId === schedulerId;

  if (auth.isUserSignedIn && isUserTheScheduler) {
    dispatch(insightActions.updateCanCancelSchedule(true));
  }
});

export const registerPageLoadObserver = thunkActionFactory<{}>(({ businessLogic }) => {
  businessLogic.insight.registerPageLoadObserver();
});

export const reportPageView = thunkActionFactory<{ pageName: string; error: any }>(
  async ({ payload, state, businessLogic }) => {
    // the problem is that insight.token is undefined
    const { sale, insight, wallet, payment, auth, config, ixp } = state;
    const { pageName, error } = payload;

    const data = {
      pageName,
      error,
      sale,
      insight,
      wallet,
      payment,
      auth,
      config,
      ixp,
      viewType: ViewType.WEB,
    };
    await businessLogic?.insight.reportPageView(data);
  }
);

export const syncBill = thunkActionFactory<{
  selectedVendor: string;
  selectedAccount: string;
  accountType: string;
  amount: number;
}>(async ({ payload, dispatch, businessLogic }) => {
  const { selectedVendor, selectedAccount, accountType, amount } = payload;
  const data = await businessLogic.insight.syncPayorCompanyBills(
    selectedVendor,
    selectedAccount,
    accountType,
    amount
  );
  if (payload) {
    dispatch(insightActions.syncBill(data));
  }
});

export const sendMerchantNotification = thunkActionFactory<{
  path: string;
  eventName?: string;
  eventsourceObjectType?: string;
}>(async ({ payload, dispatch, state }) => {
  const { path, eventName, eventsourceObjectType } = payload;
  const data = await sendEmailNotification({ state, path, eventName, eventsourceObjectType });
  if (path === 'promote_cc') {
    return;
  }
  return dispatch(insightActions.nonPayEnableEvent(data));
});

export const sendNpeFreeTextEmailEvent = thunkActionFactory<{
  merchantEmail: string;
  senderName: string;
  senderContactInfo: string;
  freeTextMessage: string;
}>(async ({ payload, dispatch, state }) => {
  const { merchantEmail, senderName, senderContactInfo, freeTextMessage } = payload;
  const originalStringLength: number =
    merchantEmail.length + senderName.length + senderContactInfo.length + freeTextMessage.length;
  const encryptedOriginalDataLength: string = encrypt(
    state.insight.domainId,
    originalStringLength.toString()
  );
  const encryptedFreeTextFormData = encrypt(
    state.insight.domainId,
    JSON.stringify({
      merchantEmail,
      senderName,
      senderContactInfo,
      freeTextMessage,
    })
  );
  const data = await sendNpeFreeTextEmail(
    state,
    encryptedFreeTextFormData,
    encryptedOriginalDataLength
  );
  return dispatch(insightActions.npeFreeTextEmailEvent(data));
});
