import HttpClient from 'server/helpers/HttpClient';
import SegmentIO from 'reporting/SegmentIO';
import { SALE_MAP, TXN_MAP } from 'types/constants';

const httpClient = HttpClient.getInstance();

const validStatusesArray = Object.keys(SALE_MAP.STATUS);
export default class Sale {
  /**
   * @param {{insight: import('./Index').default, auth: import('./Auth').default, config: import('./Config').default, initialState: import('./ICPState').default})
   */
  constructor({ initialState, config, auth, insight }) {
    this.config = config;
    this.auth = auth;
    this.insight = insight;
    this._data = initialState.sale;
  }

  async updateSaleData() {
    const {
      ticket,
      authLevel,
      realmId,
      isUserSignedIn,
      isEntityPromoted,
      entityId,
      syncToken,
      authToken,
    } = this.auth;

    const { ssrtid, portal } = this.config;

    const url = `/${portal}/rest/invoice/sale/${this.insight.token}`;
    const endpoint = `/rest/invoice/sale`;

    const headers = {
      'Intuit-ACSToken': this.insight.token,
      'Intuit-AuthLevel': authLevel,
      'Intuit-DomainId': this.insight.domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      isClientStateSignedIn: isUserSignedIn,
      isEntityPromoted: isEntityPromoted,
      sessionTicket: ticket,
      Authorization: `Bearer ${authToken}`,
    };

    if (syncToken) {
      headers['syncToken'] = syncToken;
    }

    const res = await httpClient({
      url,
      method: 'GET',
      headers,
      endpoint,
      token: this.insight.token,
      ssrtid,
    });

    const {
      amount,
      receivable: { balance },
    } = res.data.sale;
    const isPartiallyPaid = balance > 0 && balance < amount;
    return { balance, isPartiallyPaid };
  }

  async setStatusToDeclined() {
    const { ticket, realmId, entityId, authToken } = this.auth;
    const { portal } = this.config;

    const requestHeaders = {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Intuit-DomainId': this.insight.domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      'Intuit-ACSToken': this.insight.token,
      sessionTicket: ticket,
      Authorization: `Bearer ${authToken}`,
    };
    const url = `/${portal}/rest/sale/${this.insight.token}/estimate/decline`;
    const endpoint = '/rest/sale/:token/estimate/decline';
    const response = await httpClient({
      url,
      method: 'PUT',
      data: {},
      headers: requestHeaders,
      endpoint,
    });
    if (response.status === 200) {
      this.status = SALE_MAP.STATUS.REJECTED;
    } else {
      throw new Error('Could not change status of sale to declined');
    }
    SegmentIO.estimateAction({ insight: this.insight, action: 'decline' });
  }

  async setStatusToAccepted() {
    const { ticket, realmId, entityId, authToken } = this.auth;
    const { portal } = this.config;

    /**
     * don't change the status to accept when:
     * (1) The current sale is a deposit - AND -
     * (2) It is payable (a deposit that is not payable will behave same as an estimate)
     */
    if (this.requiresDeposit && this.insight.isPayable) {
      this.status = SALE_MAP.STATUS.ACCEPTED;
      SegmentIO.estimateAction({ insight: this.insight, action: 'accept' });
      return;
    }

    const requestHeaders = {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Intuit-DomainId': this.insight.domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      'Intuit-ACSToken': this.insight.token,
      sessionTicket: ticket,
      Authorization: `Bearer ${authToken}`,
    };
    const url = `/${portal}/rest/sale/${this.insight.token}/estimate/accept`;
    const endpoint = '/rest/sale/:token/estimate/accept';
    const response = await httpClient({
      url,
      method: 'PUT',
      data: {},
      headers: requestHeaders,
      endpoint,
    });
    if (response.status === 200) {
      this.status = SALE_MAP.STATUS.ACCEPTED;
    } else {
      throw new Error('Could not change status of sale to accepted');
    }
    SegmentIO.estimateAction({ insight: this.insight, action: 'accept' });
  }

  get contact() {
    return this._data.contact;
  }

  get status() {
    return this._data.status;
  }

  get amount() {
    return this._data.amount;
  }

  get convenienceFee() {
    return this._data.convenienceFee;
  }

  get data() {
    return this._data;
  }

  get balance() {
    return this._data.receivable.balance;
  }

  get allowOnlineCreditCardPayment() {
    return this._data.receivable.allowOnlineCreditCardPayment;
  }

  get allowOnlinePayment() {
    return this._data.receivable.allowOnlinePayment;
  }

  get allowPartialPayment() {
    return this._data.receivable.allowPartialPayment;
  }

  get type() {
    return this._data.type;
  }

  get subType() {
    return this._data.subType;
  }

  get requiresDeposit() {
    return this.type === TXN_MAP.TYPES.ESTIMATE && typeof this._data.requestedAmount === 'number';
  }

  get currency() {
    return this._data.currencyInfo.currency;
  }

  get customerName() {
    return this._data.contact.displayName;
  }

  get saleId() {
    if (typeof this._data.id === 'string') {
      return this._data.id;
    }
    return this._data.entity.globalId.localId;
  }

  get freight() {
    return this._data.freight;
  }

  get tax() {
    return this._data.tax && this._data.tax.totalTaxAmount;
  }

  get discount() {
    return this._data.discount;
  }

  get lines() {
    return this._data.lines;
  }

  get isGpu() {
    return this._data.isGpu;
  }

  get createdByApp() {
    return this._data.meta && this._data.meta.createdByApp;
  }

  set balanceAfterPayment({ amountPaid }) {
    let newBalanceAmount = this.balance - amountPaid;
    this._data.receivable = {
      ...this._data.receivable,
      balance: newBalanceAmount,
    };
  }

  set status(newStatus) {
    if (validStatusesArray.indexOf(newStatus) < 0) {
      throw new Error(`Sale failed setting new status. unknown status ${newStatus}`);
    }
    this._data.status = newStatus;
  }

  async fetchOpenInvoices() {
    const { ticket, realmId, entityId, authToken } = this.auth;
    const { portal } = this.config;
    const {
      contact: {
        entity: {
          globalId: { localId },
        },
      },
    } = this._data;

    const requestHeaders = {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Intuit-DomainId': this.insight.domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      'Intuit-ACSToken': this.insight.token,
      sessionTicket: ticket,
      Authorization: `Bearer ${authToken}`,
    };
    const url = `/${portal}/rest/sale/${this.insight.token}/fetchOpenInvoices?customerId=${localId}`;
    const endpoint = '/rest/sale/:token/fetchOpenInvoices';
    const request = {
      url,
      method: 'GET',
      data: {},
      headers: requestHeaders,
      endpoint,
      event: 'openInvoices',
    };
    const response = await httpClient(request);
    if (response.status === 200) {
      SegmentIO.openInvoicesNumberOfUnpaid(response.data.length);

      const { referenceNumber } = this._data;
      const openInvoices = response.data
        .filter(({ docNumber }) => docNumber !== referenceNumber || !docNumber)
        .map(({ docNumber, link, balance, dueDate, saleId }) => ({
          docNumber: docNumber || saleId,
          link,
          balance,
          dueDate,
        }));
      if (Array.isArray(openInvoices) && openInvoices.length > 0) {
        return openInvoices;
      }
    } else {
      throw new Error('Could not fetch open invoices');
    }
  }

  async fetchUnpaidInvoices() {
    const { ticket, realmId, entityId, authToken } = this.auth;
    const { portal } = this.config;
    const {
      contact: {
        entity: {
          globalId: { localId },
        },
      },
    } = this._data;

    const requestHeaders = {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Intuit-DomainId': this.insight.domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      'Intuit-ACSToken': this.insight.token,
      sessionTicket: ticket,
      Authorization: `Bearer ${authToken}`,
    };
    const url = `/${portal}/rest/sale/${this.insight.token}/fetchUnpaidInvoices?customerId=${localId}`;
    const endpoint = '/rest/sale/:token/fetchUnpaidInvoices';
    const request = {
      url,
      method: 'GET',
      data: {},
      headers: requestHeaders,
      endpoint,
      event: 'unpaidInvoices',
    };
    const response = await httpClient(request);
    if (response.status === 200) {
      const unpaidInvoices = response.data;
      if (Array.isArray(unpaidInvoices) && unpaidInvoices.length > 0) {
        return unpaidInvoices;
      }
    } else {
      throw new Error('Could not fetch unpaid invoices');
    }
  }
}
