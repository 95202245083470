import { PayloadAction } from '@reduxjs/toolkit';

import {
  signInBL,
  signOutBL,
  signUpBL,
  isFrictionlessLoginEnabledBL,
  refreshAuthBL,
} from 'businessLogic/Auth/Auth';
import { insightSelectors } from 'store/insight/selectors';
import { setIsSavePaymentMethodChecked } from 'store/payment/slice';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { Auth, AuthSignedIn } from 'types/Auth';

const initialState: Auth = {
  isFrictionlessLoginEnabled: false,
} as Auth;

const { actions, reducer } = sliceFactory({
  name: 'auth',
  initialState,
  reducers: {
    signOutFailed(state, action: PayloadAction<{ error?: Auth['authError'] }>) {
      const { error } = action.payload || {};
      if (error) {
        state.authError = error;
      }
    },
    setFrictionlessLogin(state, action: PayloadAction<{ isEnabled: boolean }>) {
      const { isEnabled } = action.payload || {};
      state.isFrictionlessLoginEnabled = isEnabled;
    },
    refreshAuthenticationSuccessful(state, action: PayloadAction<{ auth: AuthSignedIn }>) {
      return {
        ...state,
        ...action.payload.auth,
      };
    },
  },
});

export const authReducers = reducer;
export const authActions = actions;

export const signIn = thunkActionFactory(({ state }) => {
  const {
    auth: { entityId },
    config: { endpoints },
  } = state;
  state.auth.entityId;
  signInBL({ entityId, endpoints });
});

export const signUp = thunkActionFactory(({ state }) => {
  const {
    auth: { entityId },
    config: { endpoints },
  } = state;
  signUpBL({ entityId, endpoints });
});

export const signOut = thunkActionFactory(async ({ dispatch, state }) => {
  let token;
  const {
    insight,
    config: { ssrtid, portal },
  } = state;

  token = insightSelectors.tokenSelector(insight);

  try {
    await signOutBL({ ssrtid, portal, token });
  } catch (error: any) {
    dispatch(authActions.signOutFailed({ error }));
  }
});

export const checkFrictionlessLogin = thunkActionFactory(async ({ dispatch, state }) => {
  const {
    auth: { isUserSignedIn, frictionlessLoginEmail, frictionlessLoginSessionId },
    featureFlags,
  } = state;
  const isEnabled = await isFrictionlessLoginEnabledBL({
    isUserSignedIn,
    frictionlessLoginEmail,
    frictionlessLoginSessionId,
    featureFlags,
  });
  dispatch(authActions.setFrictionlessLogin({ isEnabled }));
});

/**
 * Used to update UI with the new auth state after a successful log in
 */
export const refreshAuth = thunkActionFactory(async ({ dispatch, state, businessLogic }) => {
  const {
    config: { ssrtid, portal },
    auth: { authToken },
    insight,
  } = state;
  const token = insightSelectors.tokenSelector(insight);

  const authPayload = await refreshAuthBL({
    portal,
    ssrtid,
    authToken,
    token,
  });
  dispatch(authActions.refreshAuthenticationSuccessful(authPayload));
  if (authPayload.auth.isUserSignedIn) {
    dispatch(setIsSavePaymentMethodChecked(true));
  }
  businessLogic.auth.updateData(authPayload.auth);
  return authPayload.auth;
});
