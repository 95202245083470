import { RootState } from 'store/store';

const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'store/ixp/selector';
/**
 * IXP Selector module
 */

/**
 * @typedef {import('./selector').Store} reduxStore
 */

const logToSplunk = ({ error }: { error: any }) => {
  splunkReporter.contextual({
    logInfo: { logLevel: 'warn', logger },
    event: 'viewSale',
    action: 'render',
    error: {
      message: error?.message,
      stack: error?.stack,
    },
  });
};

/**
 * Get experiment treatment name
 * @param {reduxStore} store - redux store
 * @param {string} experimentKey
 * @returns {string} treatment payload
 */
export function ixpTreatmentSelector({ ixp = {} }: IXPSelectorState, experimentKey: string) {
  try {
    if (!ixp[experimentKey]) {
      return false;
    }
    const { isRunning, control, treatmentKey } = ixp[experimentKey];
    return isRunning && !control ? treatmentKey : false;
  } catch (error) {
    logToSplunk({ error });
    return false;
  }
}

export function ixpTreatmentPayloadSelector({ ixp }: IXPSelectorState, experimentKey: string) {
  try {
    const { isRunning, treatmentPayload } = ixp[experimentKey];
    return isRunning === true ? JSON.parse(treatmentPayload) : false;
  } catch (error) {
    logToSplunk({ error });
    return false;
  }
}

export function isInIXPExperimentalTreatment(store: IXPSelectorState, experimentKey: string) {
  if (!store.ixp) {
    return false;
  }
  if (!(experimentKey in store.ixp)) {
    // Experiment not found, no need to log
    return false;
  }
  try {
    const { isRunning, control, treatmentKey, treatmentPayload } = store.ixp[experimentKey];
    const isForcedOff = treatmentPayload === 'false' && treatmentKey === 'false';

    return isRunning === true && control === false && !isForcedOff;
  } catch (error) {
    logToSplunk({ error });
    return false;
  }
}

export function isInIXPControlTreatment(store: IXPSelectorState, experimentKey: string) {
  if (!store.ixp) {
    return false;
  }
  if (!(experimentKey in store.ixp)) {
    // Experiment not found, no need to log
    return false;
  }
  try {
    const { isRunning, control } = store.ixp[experimentKey];
    return isRunning === true && control === true;
  } catch (error) {
    logToSplunk({ error });
    return false;
  }
}

export type IXPSelectorState = Pick<RootState, 'ixp' | 'featureFlags'>;

export const getTrustedCheckoutVariation = (store: IXPSelectorState) => {
  try {
    const { featureFlags = {} } = store;
    return (
      featureFlags.isTrustedCheckoutEnabled && ixpTreatmentPayloadSelector(store, 'trustedCheckout')
    );
    // return 'TEMP';
  } catch (error) {
    return null;
  }
};

export const shouldShowCKMonthlyTest = (
  store: IXPSelectorState,
  numberOFPaymentMethods: number = 0
) => {
  const ckMonthlyExperiments = ['CKMONTHLY_PE_CONSUMER', 'CKMONTHLY_NPE_CONSUMER'];
  const isCKMonthlyExperimentEnabled = ckMonthlyExperiments.reduce(
    (isInsideOneOfTheTests, currentTest) =>
      //@ts-ignore
      isInsideOneOfTheTests || ixpTreatmentSelector(store, currentTest),
    false
  );
  return isCKMonthlyExperimentEnabled && numberOFPaymentMethods < 6;
};

export const isGrowthNpeFreeTextEmailEnabled = (store: IXPSelectorState) => {
  return (
    store.featureFlags &&
    store.featureFlags['growth-npe-free-text-email'] &&
    isInIXPExperimentalTreatment(store, 'NpeFreeTextEmail')
  );
};

export const isInvoiceLineItemsEnabled = (store: IXPSelectorState) => {
  return (
    store?.featureFlags &&
    store?.featureFlags['cp-invoice-line-items'] &&
    isInIXPExperimentalTreatment(store, 'InvoiceLineItems')
  );
};

export const isGrowthPayPalCheckoutEnabled = (store: IXPSelectorState) => {
  return (
    store?.featureFlags['SBSEG-CP-growth-paypal-checkout'] &&
    ixpTreatmentSelector(store, 'PayPalCheckout')
  );
};

export const isRequestChangesEnabled = (store: IXPSelectorState) => {
  return (
    store.featureFlags &&
    store.featureFlags['request-changes'] &&
    isInIXPExperimentalTreatment(store, 'requestChanges')
  );
};

export const isPPAAMPayLaterEnabled = (store: IXPSelectorState) => {
  return (
    store.featureFlags &&
    !store.featureFlags['block-paypal-ppaam-paylater-payments'] &&
    isInIXPExperimentalTreatment(store, 'PPAAMPayLater')
  );
};

export const isRevIntelExperimentEnabled = (store: IXPSelectorState) => {
  return isInIXPExperimentalTreatment(store, 'MailchimpRevIntel');
};

export const isInIXPExperimentalTreatmentSelector =
  (IXP_KEY: string) => (store: IXPSelectorState) => {
    return isInIXPExperimentalTreatment(store, IXP_KEY);
  };

export const isIXPExperimentExistSelector =
  (IXP_KEY: string) =>
  (store: IXPSelectorState): boolean => {
    return IXP_KEY in store.ixp;
  };

export const isPostInvoiceSurveyEnabled = (store: IXPSelectorState) => {
  return store.featureFlags && store.featureFlags['SBSEG-CP-revintel-post-invoice-survey'];
};

export const isRevIntelMs2ExperienceEnabled = (store: IXPSelectorState) => {
  const featureFlags = store.featureFlags;
  return (
    featureFlags &&
    isRevIntelExperimentEnabled(store) &&
    isPostInvoiceSurveyEnabled(store) &&
    featureFlags['SBSEG-CP-REVINTEL-POST-INVOICE-SURVEY-MS2'] &&
    featureFlags['SBSEG-mc-professional-services-revintel-global-ms2']
  );
};

// global flag gating the following experience updates: post-invoice survey step expansion, testimonials & individual settings management
export const isRevIntelSurveyOptimizationsEnabled = (store: IXPSelectorState) => {
  const featureFlags = store.featureFlags;
  return (
    featureFlags &&
    isRevIntelMs2ExperienceEnabled(store) &&
    featureFlags['gbsg-revintel-survey-optimizations-settings-mgmt']
  );
};

// global flag gating the following experience updates: testimonials & individual settings management
export const isRevIntelSurveyReviewsAndSettingsEnabled = (store: IXPSelectorState) => {
  const featureFlags = store.featureFlags;
  return (
    featureFlags &&
    isRevIntelSurveyOptimizationsEnabled(store) &&
    featureFlags['gbsg-revintel-survey-reviews-testimonials-settings-mgmt']
  );
};

// component feature flag gating survey step expansion
export const shouldAlwaysShowFeedback = (store: IXPSelectorState) => {
  const featureFlags = store.featureFlags;
  return (
    featureFlags &&
    isRevIntelSurveyOptimizationsEnabled(store) &&
    featureFlags['gbsg-revintel-survey-always-show-feedback']
  );
};

// component feature flag gating testimonials
export const isRevIntelTestimonialsEnabled = (store: IXPSelectorState) => {
  const featureFlags = store.featureFlags;
  return (
    featureFlags &&
    isRevIntelSurveyOptimizationsEnabled(store) &&
    isRevIntelSurveyReviewsAndSettingsEnabled(store) &&
    featureFlags['gbsg-revintel-survey-testimonials']
  );
};

// component feature flag gating (new) survey settings management
export const isRevIntelSurveyMgmtEnabled = (store: IXPSelectorState) => {
  return (
    store?.featureFlags &&
    isRevIntelSurveyReviewsAndSettingsEnabled(store) &&
    store.featureFlags['gbsg-revintel-survey-settings-mgmt']
  );
};
