import React, { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';

import type { PayorHistory } from 'types/PayorHistory';
import type { Sale } from 'types/Sale';
import type { Wallet } from 'types/Wallet';

import { handleSubmitFeedback } from 'businessLogic/Feedback';
import { ValidatedInputQboStyle } from 'components/Core/NonPayEnabled/UnpayableCard/Growth/NpeFreeTextExperiment/ValidatedInputQboStyle';
import Button from 'components/Shared/Button/Button';
import FaceFrown from 'components/Shared/Icons/FaceFrown';
import FaceNeutral from 'components/Shared/Icons/FaceNeutral';
import FaceSmile from 'components/Shared/Icons/FaceSmile';
import Checkbox from 'components/Shared/Inputs/Checkbox';
import LabeledCheckbox from 'components/Shared/Inputs/LabeledCheckbox';
import Spinner from 'components/Shared/Spinner/Spinner';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { RootState } from 'store/store';
import { colors } from 'styles/cp';
import { Insight } from 'types/Insight';

interface FeedbackFormProps {
  onSubmit?: () => void;
  sale: Sale;
  insight: Insight;
  authToken: string;
  realmId: string;
  ssrtid: string;
  portal: string;
  wallet: Wallet;
  payorHistory: PayorHistory;
}

export interface CPFeedbackFormValues {
  feedback: string;
  payingExperience: PayingExperience | null;
  userVoice: boolean;
}

export enum PayingExperience {
  DISAPPOINTING = 'disappointing',
  NEUTRAL = 'neutral',
  GOOD = 'good',
}

export const _FeedbackForm: React.FC<FeedbackFormProps> = (props): ReactElement => {
  const { onSubmit, portal, wallet, realmId, ssrtid, authToken, sale, insight, payorHistory } =
    props;
  const splunkReporter = SplunkReporter.getInstance();
  const CONSENT_CHECKBOX_ID = 'uservoice-consent-checkbox';
  const email = useSelector((state: RootState) => state.sale.contact.toEmails?.[0]);
  const cdn = useSelector((state: RootState) => state.config.endpoints.cdn);
  const showUserVoiceConsent = !!email;
  const [formValues, setFormValues] = React.useState<CPFeedbackFormValues>({
    feedback: '',
    payingExperience: null,
    userVoice: false,
  });

  const [showPayingFeedback, setShowPayingFeedback] = React.useState(false);
  const [submittingFeedback, setSubmittingFeedback] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const successDisplayStyle = React.useMemo(
    () => (success ? { display: 'block' } : { display: 'none' }),
    [success]
  );
  const feedbackDisplayStyle = React.useMemo(
    () => (success ? { display: 'none' } : { display: 'block' }),
    [success]
  );

  const updateFormValue = (fieldName: string, value: any) =>
    setFormValues({ ...formValues, [fieldName]: value });
  const handlePayingExperienceChange = (val: PayingExperience) => {
    setFormValues({
      ...formValues,
      payingExperience: val,
    });
    !showPayingFeedback && setShowPayingFeedback(true);
  };

  const handleUserVoiceChange = () => {
    updateFormValue('userVoice', !formValues.userVoice);
  };

  const handleSubmit = async () => {
    setSubmittingFeedback(true);
    const dataToSend: CPFeedbackFormValues = { ...formValues };
    handleSubmitFeedback(dataToSend, {
      portal,
      wallet,
      realmId,
      ssrtid,
      authToken,
      sale,
      insight,
      payorHistory,
    })
      .catch((error) => {
        splunkReporter.contextual({
          logInfo: { logLevel: 'error', logger: 'FeedbackForm' },
          event: 'feedbackLink',
          action: 'submit',
          activityInfo: {
            activityObject: 'feedbackForm',
            error: error,
          },
        });
      })
      .finally(() => {
        setSuccess(true);
        setSubmittingFeedback(true);
        if (onSubmit) {
          setTimeout(onSubmit, 2000);
        }
      });
  };

  const ThankYouMessage = () => (
    <FormattedMessage
      id="FEEDBACK_FORM_THANK_YOU_MESSAGE"
      defaultMessage="Thanks for your feedback"
    />
  );

  const FormTitle = () => (
    <FormattedMessage id="FEEDBACK_FORM_TITLE" defaultMessage="How is your paying experience?" />
  );

  const AdditionalFeedbackTitle = () => (
    <FormattedMessage
      id={'FEEDBACK_FORM_ADDITIONAL_FEEDBACK'}
      defaultMessage={'We’d love any additional feedback'}
    />
  );

  const FormSubtitle = () => (
    <FormattedMessage
      id={'FEEDBACK_FORM_SUBTITLE'}
      defaultMessage={'Your feedback will be used to improve Quickbooks checkout'}
    />
  );

  const PublicFeedbackConsent = () => (
    <label htmlFor={CONSENT_CHECKBOX_ID}>
      <FormattedMessage
        id="FEEDBACK_FORM_PUBLIC_FEEDBACK_CONSENT"
        values={{
          // @ts-ignore
          bold: (chunks: string) => (<b>{chunks}</b>) as ReactNode,
          // @ts-ignore
          a: (chunks: string) => (
            <a
              href="https://feedback.qbo.intuit.com/forums/920104-quickbooks-invoice-online-checkout/filters/top"
              target="_blank"
              rel="noreferrer"
            >
              {chunks}
            </a>
          ),
        }}
        defaultMessage={
          '<bold>Also share publicly</bold>: Post my feedback on the <a>QuickBooks forum</a>'
        }
      />
    </label>
  );

  const renderFormButton = React.useMemo(() => {
    return submittingFeedback ? (
      <Spinner width={40} />
    ) : (
      <Button
        key="submit"
        onClick={handleSubmit}
        buttonType="primary"
        disabled={submittingFeedback}
      >
        Send
      </Button>
    );
  }, [submittingFeedback, handleSubmit]);

  return (
    <>
      <div className="feedback-form">
        <div
          className="success-message"
          data-testid="success-message"
          style={{ ...successDisplayStyle }}
        >
          <img src={`${cdn}/feedback_success.png`} alt="Success" />
          <p>
            <ThankYouMessage />
          </p>
        </div>
        <div style={{ ...feedbackDisplayStyle }}>
          <div className="paying-experience">
            <div className="title">
              <FormTitle />
            </div>
            <div className="content">
              <span data-testid="checkbox-disappointing">
                <Checkbox
                  checked={formValues.payingExperience === PayingExperience.DISAPPOINTING}
                  onChange={() => handlePayingExperienceChange(PayingExperience.DISAPPOINTING)}
                  disabled={false}
                  icon={FaceFrown}
                  withIcon={true}
                  iconSelectedColor={colors.orangeBright}
                />
              </span>
              <span data-testid="checkbox-neutral">
                <Checkbox
                  checked={formValues.payingExperience === PayingExperience.NEUTRAL}
                  onChange={() => handlePayingExperienceChange(PayingExperience.NEUTRAL)}
                  disabled={false}
                  icon={FaceNeutral}
                  withIcon={true}
                  iconSelectedColor={colors.intuit_blue}
                />
              </span>
              <span data-testid="checkbox-good">
                <Checkbox
                  checked={formValues.payingExperience === PayingExperience.GOOD}
                  onChange={() => handlePayingExperienceChange(PayingExperience.GOOD)}
                  disabled={false}
                  icon={FaceSmile}
                  withIcon={true}
                  iconSelectedColor={colors.green}
                />
              </span>
            </div>
          </div>
          {showPayingFeedback && (
            <>
              <div className="paying-feedback">
                <div className="title">
                  <AdditionalFeedbackTitle />
                </div>
                <div className={'subtitle'}>
                  <FormSubtitle />
                </div>
                <div className="content">
                  <ValidatedInputQboStyle
                    onChangeHandler={(e) => updateFormValue('feedback', e.target.value)}
                    validationFunction={() => true}
                    isTextArea={true}
                    disabled={submittingFeedback}
                  />
                </div>
              </div>
              {showUserVoiceConsent && (
                <div className="feedback-sharing">
                  <LabeledCheckbox
                    id={CONSENT_CHECKBOX_ID}
                    disabled={submittingFeedback}
                    checked={formValues.userVoice}
                    onChange={handleUserVoiceChange}
                    label={
                      <div className="uservoice-consent">
                        <div className="uservoice-consent--title">
                          <PublicFeedbackConsent />
                        </div>
                        <div className="uservoice-consent--description"></div>
                      </div>
                    }
                  />
                </div>
              )}
              <div style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
                <span data-testid="submit-feedback-button">{renderFormButton}</span>
              </div>
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        .feedback-form {
          color: ${colors.gray};
          margin: 0 auto 20px auto;
          width: 100%;

          .title {
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
            text-align: center;
            margin: 10px 0;
          }

          .content {
            margin: 10px 0;
          }

          .paying-experience {
            margin-top: 40px;

            .content {
              display: flex;
              justify-content: center;
              gap: 30px;
              margin: 20px 0;
              left: -10px;
              position: relative;
            }
          }

          .paying-feedback {
            max-width: 450px;
            margin: 60px auto 0 auto;

            .content {
              display: flex;
              justify-content: center;
            }
          }

          .subtitle {
            text-align: center;
          }

          .feedback-sharing {
            max-width: 450px;
            margin: 20px auto;
            text-align: left;

            .uservoice-consent {
              margin: 0 5px;

              &--title {
                font-weight: 500;
                font-size: 14px;
                color: ${colors.gray};
              }

              &--description {
                font-weight: 400;
                font-size: 12px;
                color: ${colors.gray02};
              }
            }
          }
        }

        .success-message {
          text-align: center;
          font-size: 20px;
          font-weight: bold;
        }
      `}</style>
    </>
  );
};

function mapStateToProps(state: RootState) {
  const {
    config: { ssrtid, portal },
    insight,
    auth: { authToken, realmId },
    sale,
    wallet,
    payorHistory,
  } = state;
  return {
    sale,
    insight,
    authToken,
    realmId,
    ssrtid,
    portal,
    wallet,
    payorHistory,
  };
}

export const FeedbackForm = connect(mapStateToProps)(_FeedbackForm);
