import React, { useState } from 'react';

import FeedbackModal from './FeedbackModal/FeedbackModal';

import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { fontSize, colors, breakpoints } from 'styles/cp';

const logger = 'pages/Feedback';

const splunkReporter = SplunkReporter.getInstance();

export interface FeedbackButtonProps {
  transactionType?: 'INVOICE' | 'PAYMENT_REQUEST' | string;
  sectionName?: 'success-screen' | 'main-section';
}

export const FeedbackButton: React.FC<FeedbackButtonProps> = (props: FeedbackButtonProps) => {
  const { transactionType = 'INVOICE', sectionName = 'main-section' } = props;
  const onClick = React.useCallback(() => {
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'feedbackLink',
      action: 'click',
      activityInfo: {
        activityObject: 'feedbackButton',
      },
    });
    setShowFeedback(true);
  }, []);

  const classnames = React.useMemo(() => {
    let classNames = 'feedback-link feedback-w desktop';

    if (sectionName === 'success-screen') {
      classNames += ' always-show';
    }

    if (transactionType === 'PAYMENT_REQUEST') {
      classNames += ' payment-request';
    }
    return classNames;
  }, [transactionType, sectionName]);
  const [showFeedback, setShowFeedback] = useState(false);
  return (
    <React.Fragment>
      <div className={classnames}>
        <a rel="noopener noreferrer" data-testid="feedbackv2btn" onClick={onClick}>
          <span>Feedback</span>
        </a>
      </div>
      {showFeedback && <FeedbackModal onHide={() => setShowFeedback(false)} />}
      <style jsx>{`
        .payment-request {
          display: none;
        }

        .feedback-w.desktop {
          position: fixed;
          z-index: 999;
          transform-origin: bottom;
          transform: translate(50%, -100%) rotate(-90deg);
          right: 0px;
          bottom: 48px;
          height: 40px;
          line-height: 40px;

          a {
            background: ${colors.gray06};
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            border: solid 1px ${colors.gray06};
            text-decoration: none;
            color: ${colors.gray03};
            cursor: pointer;
            height: 40px;
            width: 125px;
            font-size: ${fontSize.sm};
            line-height: 40px;
            padding: 10px 25px;

            &:active {
              color: ${colors.gray03};
            }
          }

          @media screen and (max-width: ${breakpoints.md}) {
            display: none;
          }
        }

        .feedback-w.mobile {
          text-align: center;
          margin: 15px 0 10px;
          font-size: ${fontSize.xs};

          @media not screen and (max-width: ${breakpoints.md}) {
            display: none;
          }
        }
      `}</style>
    </React.Fragment>
  );
};
